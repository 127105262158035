import axios from 'axios'

axios.interceptors.response.use(
  response => {
    const data = JSON.stringify(response.data)
      .replace(/hcm01.vstorage.vngcloud.vn\/v1\/AUTH_77cdabdfd5ab49fd896ce6b19d7019dc/g, 'hcm03.vstorage.vngcloud.vn/v1/AUTH_359c137a6cf647cdb070a4808cbeaf8d')
      .replace(/hcm01.vstorage.vngcloud.vn\/v1\/AUTH_77cdabdfd5ab49fd896ce6b19d7019dc/g, 'hcm03.vstorage.vngcloud.vn/v1/AUTH_359c137a6cf647cdb070a4808cbeaf8d')
      .replace(/vsports.blob.core.windows.net\/files/g, '19429431-vsports.s3-hcm5-r1.longvan.net')
      .replace(/vsports.blob.core.windows.net\/messages/g, '19429431-vsports.s3-hcm5-r1.longvan.net')
      .replace(/vsports.blob.core.windows.net\/posts/g, '19429431-vsports.s3-hcm5-r1.longvan.net')
      .replace(/vsports-assets-hfevhbaxghane9f2.z01.azurefd.net\/files/g, '19429431-vsports.s3-hcm5-r1.longvan.net')
      .replace(/vsports-assets-hfevhbaxghane9f2.z01.azurefd.net\/messages/g, '19429431-vsports.s3-hcm5-r1.longvan.net')
      .replace(/vsports-assets-hfevhbaxghane9f2.z01.azurefd.net\/posts/g, '19429431-vsports.s3-hcm5-r1.longvan.net')
    response.data = JSON.parse(data)
    return response
  },
  async function (error) {
    return error
  }
)

export function getTeamsBySeasonId(leagueId, seasonId, teamId) {
  return axios({
    url: `/api/v1/leagues/${leagueId}/seasons/${seasonId}/teams/${teamId}`,
    method: 'get'
  }).then(res => res.data)
}

export function getRankingTable(league_id, season_id) {
  return axios({
    url: `/api/v1/leagues/${league_id}/seasons/${season_id}/ranking`,
    method: 'get'
  }).then(res => res.data)
}

export function getSeasonById(leagueId, seasonId) {
  return axios({
    url: `/api/v1/leagues/${leagueId}/seasons/${seasonId}`,
    method: 'get'
  }).then(res => res.data)
}
export function getLeagueById(leagueId) {
  return axios({
    url: `/api/v1/leagues/${leagueId}`,
    method: 'get'
  }).then(res => res.data)
}
export function getPlayersBySeasonId(seasonId, teamId) {
  return axios({
    url: `api/v1/seasons/${seasonId}/teams/${teamId}/players?status=accepted`,
    method: 'get'
  })
}

//
export function old_getLeagueById(id, season) {
  const q = season ? `?season=${season}` : ''
  return axios({
    baseURL: 'https://apis.vsports.vn',
    url: `/v1/leagues/${id}${q}`,
    method: 'get'
  }).then(res => res.data)
}
export function old_getTeamPlayersBySeasonId(seasonId, teamId) {
  return axios({
    baseURL: 'https://apis.vsports.vn',
    url: `/v1/seasons/${seasonId}/teams/${teamId}/players`,
    method: 'get'
  }).then(res => res.data)
}

export function getTreeDiargram(leagueId, seasonId, type) {
  return axios({
    url: `/api/v1/leagues/${leagueId}/seasons/${seasonId}/rounds/${type}/matches/all/keyGoOrBack/all/series/all`,
    method: 'get'
  })
}
export function getMatchById(leagueId, matchId) {
  return axios({
    url: `/api/v1/leagues/${leagueId}/matches/${matchId}`,
    method: 'get'
  }).then(res => res.data)
}
export function getMatchDetails(leagueId, seasonId, matchId) {
  return axios({
    url: `/api/v1/leagues/${leagueId}/seasons/${seasonId}/matches/${matchId}/details`,
    method: 'get'
  }).then(res => res.data)
}
//get lich
export function getSchedulesBySeasonIdV2(leagueId, seasonId, numberRound, type, keyGoOrBack, series) {
  return axios({
    url: `/api/v1/leagues/${leagueId}/seasons/${seasonId}/rounds/${numberRound}/matches/${type}/keyGoOrBack/${keyGoOrBack}/series/${series}`,
    method: 'get'
  }).then(res => res.data)
}
export function getSeasonByIdSeasonAndIdLeague(leagueId, seasonId) {
  return axios({
    url: `/api/v1/leagues/${leagueId}/seasons/${seasonId}`,
    method: 'get'
  }).then(res => res.data)
}
export function getTableByIdSeasonAndIdLeague(leagueId, seasonId) {
  return axios({
    url: `/api/v1/leagues/${leagueId}/seasons/${seasonId}/ranking`,
    method: 'get'
  }).then(res => res.data)
}

export function getTeamAcceptedBySeasonId(league_id, season_id, page = 1, perPage = 10) {
  return axios({
    url: `/api/v1/leagues/${league_id}/seasons/${season_id}/teams/all/status/accepted?page=${page}&perPage=${perPage}`,
    method: 'get'
  }).then(res => res.data)
}
export function getSponsor(leagueId, seasonId) {
  return axios({
    url: `/api/v1/leagues/${leagueId}/seasons/${seasonId}/sponsorTypes`,
    method: 'get'
  }).then(res => res.data)
}
