import React from 'react'
import moment from 'moment'
import img from '../../../public/static/image-background/logo-v-02.png'
import imgbgborder from '../../../public/static/image-background/c1_bgborder_table.png'
import {json} from 'react-router-dom'
import locales from '../../../public/static/locales/vi.json'
import {getBase64, useTranslation} from '../../../constants/helpers'

const Mau2 = ({dataSeason, dataTable, size, id}) => {
  const {t} = useTranslation()
  const width = size
  const px = size / 100
  const color = {
    first: '#f48220',
    second: '#325ea8',
    third: '#ffe5c2',
    fourth: '#1d4380'
  }
  return (
    <div id={id} className="bg-match-mau1" style={{width: width, minHeight: width / 1.3, padding: `${1.6 * px}px`, fontFamily: 'SFUHelveticaCondensedBold'}}>
      <div style={{width: `100%`, minHeight: `calc(${width / 1.3}px - ${3.2 * px}px)`, padding: `${px * 1.2}px ${px * 1.2}px ${px * 4.2}px ${px * 1.2}px`, backgroundColor: '#fff', position: 'relative'}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div style={{flex: 1}}>
            <Image src={dataSeason?.logo} style={{width: `${px * 5}px`, height: 'max-content'}} />
          </div>
          <div style={{flex: 6, display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
            <h1 style={{textAlign: 'center', color: color.fourth, fontSize: `${2.5 * px}px`, textTransform: 'uppercase'}}>{dataSeason?.name}</h1>
          </div>
          <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <Image src={img} style={{width: `${px * 5}px`, height: 'max-content'}} />
          </div>
        </div>
        <h1 style={{textAlign: 'center', color: color.first, fontSize: `${4.5 * px}px`, textTransform: 'uppercase'}}>{t('final_draw')}</h1>
        <div style={{width: `100%`, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
          <div style={{width: `${70 * px}px`, display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: `${2 * px}px`}}>
            {dataTable?.map((i, idx) => (
              <Item key={`${idx}${new Date()}`} data={i} px={px} idx={idx} classCustom={`${i.name}`} />
            ))}
          </div>
        </div>

        <p style={{textAlign: 'center', color: color.first, fontSize: `${2 * px}px`, position: 'absolute', bottom: `0px`, right: `${1.2 * px}px`}}>{window.sessionStorage.getItem('HomeUrl') || 'VSPORTS.VN'}</p>
      </div>
    </div>
  )
}
const Item = React.memo(
  ({data, px, classCustom, idx}) => {
    const {t} = useTranslation()
    const color = {
      first: '#f48220',
      second: '#325ea8',
      third: '#ffe5c2',
      fourth: '#1d4380'
    }
    if ((idx + 1) % 2 == 0) {
      return (
        <div style={{width: `${32 * px}px`, margin: `${0.5 * px}px`, display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
          <div style={{height: 'auto', backgroundImage: `linear-gradient(180deg, #1c407b, #0d141c)`, padding: `${0.5 * px}px`, display: 'flex', flexDirection: 'column', width: '100%', borderRadius: `${px}px`}}>
            <div style={{backgroundImage: `linear-gradient(180deg, #3263af, #263883)`, borderRadius: `${px}px`, padding: `${1 * px}px`}}>
              {data?.teams?.map((i, idx) => (
                <div key={`${idx}${i.id}`} style={{display: 'flex', alignItems: 'center', flexDirection: 'row', borderTop: idx != 0 ? '1px solid #ffe2ae' : 'unset', marginTop: idx != 0 ? `${px}px` : 'unset', paddingTop: idx != 0 ? `${px}px` : 'unset'}}>
                  <h1 style={{color: '#ffe2ae', fontSize: `${1.7 * px}px`, textTransform: 'uppercase'}}>
                    {data?.name}
                    {idx + 1}
                  </h1>
                  <Name name={i.name} px={px} propClass={`class${classCustom}${idx}${new Date().getTime()}`} />
                  {/* <h2 style={{color: '#fff', fontSize: `${1.6 * px}px`, marginLeft: `${px}px`, textTransform: 'uppercase', fontFamily: 'SFUHelveticaCondensedBoldOblique'}}>{i.name}</h2> */}
                </div>
              ))}
            </div>
          </div>
          <div style={{width: `${13 * px}px`, padding: `${px}px`, backgroundImage: `url(${imgbgborder})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', transform: 'rotate(90deg)', position: 'absolute', right: `-${8 * px}px`, fontFamily: 'SFUHelveticaCondensedBlack'}}>
            <h2 style={{color: '#ffe2ae', textAlign: 'center', fontSize: `${1.8 * px}px`, lineHeight: `${1.2 * px}px`, padding: `0px ${2 * px}px`}}>
              {t('group')} {data?.name}
            </h2>
          </div>
        </div>
      )
    }
    return (
      <div style={{width: `${32 * px}px`, margin: `${0.5 * px}px`, display: 'flex', flexDirection: 'row', alignItems: 'center', position: 'relative'}}>
        <div style={{width: `${13 * px}px`, padding: `${px}px`, backgroundImage: `url(${imgbgborder})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat', transform: 'rotate(-90deg)', position: 'absolute', left: `-${8 * px}px`, fontFamily: 'SFUHelveticaCondensedBlack'}}>
          <h2 style={{color: '#ffe2ae', textAlign: 'center', fontSize: `${1.8 * px}px`, lineHeight: `${1.2 * px}px`, padding: `0px ${2 * px}px`}}>
            {t('group')} {data?.name}
          </h2>
        </div>
        <div style={{height: 'auto', backgroundImage: `linear-gradient(180deg, #1c407b, #0d141c)`, padding: `${0.5 * px}px`, display: 'flex', flexDirection: 'column', width: '100%', borderRadius: `${px}px`}}>
          <div style={{backgroundImage: `linear-gradient(180deg, #3263af, #263883)`, borderRadius: `${px}px`, padding: `${1 * px}px`}}>
            {data?.teams?.map((i, idx) => (
              <div key={`${idx}${i.id}`} style={{display: 'flex', alignItems: 'center', flexDirection: 'row', borderTop: idx != 0 ? '1px solid #ffe2ae' : 'unset', marginTop: idx != 0 ? `${px}px` : 'unset', paddingTop: idx != 0 ? `${px}px` : 'unset'}}>
                <h1 style={{color: '#ffe2ae', fontSize: `${1.7 * px}px`, textTransform: 'uppercase'}}>
                  {data?.name}
                  {idx + 1}
                </h1>
                <Name name={i.name} px={px} propClass={`class${classCustom}${idx}${new Date().getTime()}`} />
                {/* <h2 style={{color: '#fff', fontSize: `${1.6 * px}px`, marginLeft: `${px}px`, textTransform: 'uppercase', fontFamily: 'SFUHelveticaCondensedBoldOblique'}}>{i.name}</h2> */}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  },
  (p, n) => JSON.stringify(p.data) === JSON.stringify(n.data)
)

const Name = React.memo(
  ({name, px, propClass}) => {
    const reSizeBasedOnWidth = () => {
      let fontSize = window && window?.getComputedStyle(document.querySelector(`.${propClass}`))?.fontSize
      document.querySelector(`.${propClass}`).style.fontSize = parseFloat(fontSize) - 0.01 * px + 'px'
      if (document.querySelector(`.${propClass}`).clientHeight >= document.querySelector(`.contain${propClass}`).clientHeight) {
        reSizeBasedOnWidth()
      }
    }
    const processInput = () => {
      document.querySelector(`.${propClass}`).innerHTML = name
      document.querySelector(`.${propClass}`).style.fontSize = px * 1.8 + 'px' // Default font size
      reSizeBasedOnWidth()
    }
    React.useEffect(() => {
      processInput()
    }, [name])

    return (
      <div className={`contain${propClass}`} style={{flex: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', height: `${3 * px}px`, textTransform: 'uppercase'}}>
        <h2 className={propClass} style={{color: '#fff', marginLeft: `${px}px`, textTransform: 'uppercase', fontFamily: 'SFUHelveticaCondensedBoldOblique'}}></h2>
      </div>
    )
  },
  (p, n) => JSON.stringify(p.name) === JSON.stringify(n.name) && JSON.stringify(p.propClass) && JSON.stringify(n.propClass)
)

const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return <img {...props} src={data} alt="" />
}

function areEqual(p, n) {
  return JSON.stringify(p.isDisabledTime) === JSON.stringify(n.isDisabledTime) && JSON.stringify(p.dataMatch) === JSON.stringify(n.dataMatch) && JSON.stringify(p.dataMatchDetail) === JSON.stringify(n.dataMatchDetail)
}

export default React.memo(Mau2, areEqual)
