import React, {useEffect} from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter, Routes, Route, useSearchParams, useFetcher} from 'react-router-dom'
import axios from 'axios'
import './index.css'
import ExportLayerLeague from './components/exportlayerleague'
import Empty from './components/empty'
import {extendTheme, ChakraProvider} from '@chakra-ui/react'
import Thedeo from './components/thedeo'
import ThedeoV2 from './components/thedeoV2'
import TheDeoAll from './components/thedeoAll'
import Ranking from './components/ranking'
import Treediargram8 from './components/treediargram8'
import Treediargram16 from './components/treediargram16'
import Treediargram4 from './components/treediargram4'

import Luckydraw from './components/luckydraw'
import AdminManagement from './components/adminmanagement'
import LuckydrawOnline from './components/luckydrawonline'
import ExportTable from './components/exportTable'
import ExportMatchDetail from './components/exportmatchdetail'
import ExportSchedules from './components/schedules/exportSchedules'

import ExportSchedulesSemiFinal from './components/schedules/exportSchedulesSemiFinal'
import ExportSchedulesFinal3rdPlace from './components/schedules/exportSchedulesFinal3rdPlace'
import ExportSchedulesMatch from './components/schedules/exportschedulesmatch'

import ExportResults from './components/results/exportResults'
import ExportResultsSemiFinal from './components/results/exportResultsSemiFinal'
import ExportResultsFinal3rdPlace from './components/results/exportSchedulesFinal3rdPlace'
import ExportlayerleagueAll from './components/exportlayerleagueAll'
import ThedeoV2Basketball from './components/basketball/thedeoV2Basketball'

/////Bong ro

import ExportLayerLeagueBasketball from './components/basketball/exportlayerleague'
import ExportSchedulesBasketball from './components/basketball/schedules/exportSchedules'
import ExportSchedulesSemiFinalBasketball from './components/basketball/schedules/exportSchedulesSemiFinal'
import ExportSchedulesFinal3rdPlaceBasketball from './components/basketball/schedules/exportSchedulesFinal3rdPlace'

const customTheme = {
  fonts: {
    body: 'Inter, sans-serif',
    heading: 'Inter, sans-serif',
    mono: 'Inter, sans-serif'
  }
}

const Auth = ({children}) => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('t') !== 'undefined' ? searchParams.get('t') : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MiwibmFtZSI6IkFub255bW91cyIsImF2YXRhciI6IiIsInR5cGUiOiJhbm9ueW1vdXMiLCJsYW5ndWFnZV9pZCI6ImVuIiwiaWF0IjoxNjUzMzQwOTE5fQ.9BMudg88cBjqhLzB1BAvg7SKgm1cSEbV04leVW-ety8'
  const baseURL = searchParams.get('baseURL')
  const lang = searchParams.get('lang') || 'vi'
  useEffect(() => {
    window.sessionStorage.setItem('lang', lang)
    if (searchParams.get('baseURL').includes('.us')) {
      window.sessionStorage.setItem('HomeUrl', 'VSPORTS.US')
    }
  }, [])
  if (token === 'undefined') {
    return <Empty message={'Chưa đăng nhập'} />
  }
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
  axios.defaults.baseURL = baseURL === 'undefined' || !baseURL ? 'https://vsports.vn/' : baseURL
  return children
}

const theme = extendTheme(customTheme)

// import Expenses from "./routes/expenses";
// import Invoices from "./routes/invoices";

const root = ReactDOM.createRoot(document.getElementById('root'))

root.render(
  <BrowserRouter>
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<h1>vsports.com.vn</h1>} />
        <Route
          path="leagues/:leagueId/seasons/:seasonId/teams/:teamId"
          element={
            <Auth>
              <ExportLayerLeague />
            </Auth>
          }
        />
        <Route
          path="exportlayerleagues/league/:leagueId/season/:seasonId/team/:teamId"
          element={
            <Auth>
              <ExportLayerLeague />
            </Auth>
          }
        />
        {/* Thien add */}
        <Route
          path="exportlayerleaguesAll/league/:leagueId/season/:seasonId/team/:teamId"
          element={
            <Auth>
              <ExportlayerleagueAll />
            </Auth>
          }
        />

        <Route
          path="ranking/league/:leagueId/season/:seasonId"
          element={
            <Auth>
              <Ranking />
            </Auth>
          }
        />

        {/* Thien add */}
        <Route
          path="thedeo/league/:leagueId/season/:seasonId"
          element={
            <Auth>
              <Ranking />
            </Auth>
          }
        />
        <Route
          path="thedeoV2/league/:leagueId/season/:seasonId/team/:teamId"
          element={
            <Auth>
              <ThedeoV2 />
            </Auth>
          }
        />
        <Route
          path="thedeoV2Basketball/league/:leagueId/season/:seasonId/team/:teamId"
          element={
            <Auth>
              <ThedeoV2Basketball />
            </Auth>
          }
        />
        <Route
          path="thedeoAll/league/:leagueId/season/:seasonId"
          element={
            <Auth>
              <TheDeoAll />
            </Auth>
          }
        />
        <Route
          path="treediargram16/league/:leagueId/season/:seasonId"
          element={
            <Auth>
              <Treediargram16 />
            </Auth>
          }
        />
        <Route
          path="treediargram8/league/:leagueId/season/:seasonId"
          element={
            <Auth>
              <Treediargram8 />
            </Auth>
          }
        />
        <Route
          path="treediargram4/league/:leagueId/season/:seasonId"
          element={
            <Auth>
              <Treediargram4 />
            </Auth>
          }
        />
        <Route
          path="/luckydraw"
          element={
            <Auth>
              <Luckydraw />
            </Auth>
          }
        />
        <Route
          path="/luckydrawonline"
          element={
            <Auth>
              <LuckydrawOnline />
            </Auth>
          }
        />
        <Route
          path="/luckydraw/admin-setting-v_station_manager"
          element={
            <Auth>
              <AdminManagement />
            </Auth>
          }
        />
        <Route
          path="/exportmatchdetail/league/:leagueId/season/:seasonId/match/:matchId"
          element={
            <Auth>
              <ExportMatchDetail />
            </Auth>
          }
        />
        <Route
          path="/exportSchedules/league/:leagueId/season/:seasonId"
          element={
            <Auth>
              <ExportSchedules />
            </Auth>
          }
        />
        <Route ///bong ro
          path="/exportSchedulesBasketball/league/:leagueId/season/:seasonId"
          element={
            <Auth>
              <ExportSchedulesBasketball />
            </Auth>
          }
        />
        <Route
          path="/exportSchedulesSemiFinal/league/:leagueId/season/:seasonId"
          element={
            <Auth>
              <ExportSchedulesSemiFinal />
            </Auth>
          }
        />
        <Route // bong ro
          path="/exportSchedulesSemiFinalBasketball/league/:leagueId/season/:seasonId"
          element={
            <Auth>
              <ExportSchedulesSemiFinalBasketball />
            </Auth>
          }
        />
        <Route
          path="/exportSchedulesFinal3rdPlace/league/:leagueId/season/:seasonId"
          element={
            <Auth>
              <ExportSchedulesFinal3rdPlace />
            </Auth>
          }
        />
        <Route // bong ro
          path="/exportSchedulesFinal3rdPlaceBasketball/league/:leagueId/season/:seasonId"
          element={
            <Auth>
              <ExportSchedulesFinal3rdPlaceBasketball />
            </Auth>
          }
        />
        <Route
          path="/exportTable/league/:leagueId/season/:seasonId"
          element={
            <Auth>
              <ExportTable />
            </Auth>
          }
        />
        <Route
          path="/exportScheduleMatch/league/:leagueId/season/:seasonId/match/:matchId"
          element={
            <Auth>
              <ExportSchedulesMatch />
            </Auth>
          }
        />
        <Route // bong ro
          path="/exportScheduleMatchBasketball/league/:leagueId/season/:seasonId/match/:matchId"
          element={
            <Auth>
              <ExportSchedulesMatch />
            </Auth>
          }
        />
        <Route
          path="/exportResults/league/:leagueId/season/:seasonId"
          element={
            <Auth>
              <ExportResults />
            </Auth>
          }
        />
        <Route
          path="/exportResultsSemiFinal/league/:leagueId/season/:seasonId"
          element={
            <Auth>
              <ExportResultsSemiFinal />
            </Auth>
          }
        />
        <Route
          path="/exportResultsFinal3rdPlace/league/:leagueId/season/:seasonId"
          element={
            <Auth>
              <ExportResultsFinal3rdPlace />
            </Auth>
          }
        />
        <Route
          path="exportlayerleaguesbasketball/league/:leagueId/season/:seasonId/team/:teamId"
          element={
            <Auth>
              <ExportLayerLeagueBasketball />
            </Auth>
          }
        />
      </Routes>
    </ChakraProvider>
  </BrowserRouter>
)
