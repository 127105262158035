import React from 'react'
import moment from 'moment'
import * as htmlToImage from 'html-to-image'
import ReactPaginate from 'react-paginate'
import {Button, Box, Text, Flex, Checkbox, Tooltip} from '@chakra-ui/react'
import {useParams, useLocation} from 'react-router-dom'
import {MdArrowBackIos} from 'react-icons/md'
import MauRanking1 from './components/mauranking1'
import MauRanking2 from './components/mauranking2'
import bgmau1 from '../../public/static/image-background/mau1ranking.jpeg'
import bgmau2 from '../../public/static/image-background/mau2ranking.jpeg'
import bgmauus1 from '../../public/static/image-background/mauus1ranking.jpeg'
import bgmauus2 from '../../public/static/image-background/mauus2ranking.jpeg'
import {MdUpdate} from 'react-icons/md'
import Loading from '../Loading'

import * as leaguesAPI from '../../api/leagues'
import {useTranslation, formatDateTime, getBase64} from '../../constants/helpers'

const ExportRanking = () => {
  const {t} = useTranslation()
  let {leagueId, seasonId} = useParams()
  const [templateType, setTemplateType] = React.useState(null)
  const lang = window.sessionStorage.getItem('lang')
  const thumbnail = {
    type1: {
      en: bgmauus1,
      vi: bgmau1
    },
    type2: {
      en: bgmauus2,
      vi: bgmau2
    }
  }
  const [state, setState] = React.useState({
    loadingSeason: true,
    loadingRanking: true,
    downloading: false,
    loading: true,
    data: [],
    league: {},
    dataSeason: []
  })
  const [selected, setSelected] = React.useState(0)
  const [show, setShow] = React.useState(false)

  const idMau1 = React.useRef(new Date().toISOString())
  const idMau2 = React.useRef(new Date().toISOString())

  const select = value => () => {
    setSelected(value)
    setShow(true)
  }

  React.useEffect(() => {
    switch (selected) {
      case 1:
        setTemplateType(idMau1.current)
        break
      case 2:
        setTemplateType(idMau2.current)
        break
      default:
        break
    }
  }, [selected])

  const fetchData = async () => {
    try {
      const res = await leaguesAPI.getRankingTable(leagueId, seasonId).then(res => res)
      const resSeason = await leaguesAPI.getLeagueById(leagueId)
      setState(s => ({...s, league: resSeason, dataSeason: resSeason.seasons.filter(i => i.id === seasonId)[0], data: [...res], loading: false, loadingSeason: false, loadingRanking: false}))
    } catch (error) {
      console.log(error)
      setState(s => ({...s, loading: false}))
    }
  }

  React.useEffect(() => {
    fetchData()
    setState(s => ({...s, downloading: true}))
    const timeOut = setTimeout(() => {
      setState(s => ({...s, downloading: false}))
    }, 1000)
    return () => {
      clearTimeout(timeOut)
      setState(s => ({...s, downloading: true}))
    }
  }, [])

  const css = {
    cursor: 'pointer',
    // border: '2px solid #fff',
    borderRadius: '2px'
  }

  const exportImage = async () => {
    if (state.downloading) return
    setState(s => ({...s, downloading: true}))
    htmlToImage
      .toJpeg(document.getElementById(templateType), {pixelRatio: 3, quality: 1})
      .then(dataUrl => {
        var link = document.createElement('a')
        const timeStamp = moment().format('yyyyMMDDHHmmss')
        const text = {
          vi: 'BangXepHang',
          en: 'Tables'
        }
        link.download = `${text[lang]}_${state.dataSeason?.name}_${timeStamp}.jpeg`
        link.href = dataUrl
        link.click()
        setState(s => ({...s, downloading: false}))
      })
      .catch(function (error) {
        setState(s => ({...s, downloading: false}))
        console.error('oops, something went wrong!', error)
      })
  }
  React.useEffect(() => {
    window.parent.postMessage('ExportRanking', '*')
  }, [])

  if (state.loadingSeason || state.loadingRanking) {
    return (
      <Box height={'50vh'} display="flex" flexDirection={'column'} alignItems={'center'} justifyContent="center">
        <Text>Loanding. . . !</Text>
      </Box>
    )
  }
  if (show) {
    return (
      <Box>
        <Flex display={'flex'} flexDirection="row" justifyContent={'space-between'}>
          <Box
            display={'flex'}
            flexDirection={'row'}
            mb={3}
            onClick={() => {
              setShow(false)
            }}
            cursor="pointer">
            <MdArrowBackIos size={'28px'} />
            <Text> {t('back')}</Text>
          </Box>
          <Flex display="flex" flexDirection={'row'} justifyContent="flex-end">
            <Button ml={4} isLoading={state.downloading} colorScheme="blue" onClick={exportImage}>
              {t('download')}
            </Button>
          </Flex>
        </Flex>
        <Flex mt={4} display={'flex'} flexDirection="row" justifyContent={'center'}>
          <Box>
            <Box maxWidth={'1000px'} margin="0 auto" display="flex" flexDirection={'column'} justifyContent="center">
              {selected === 1 && (
                <>
                  <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <MauRanking1 size={1280} dataTable={state.data} dataSeason={state.dataSeason} id={idMau1.current} />
                  </div>
                  <MauRanking1 size={468} dataTable={state.data} dataSeason={state.dataSeason} />
                </>
              )}
              {selected === 2 && (
                <>
                  <div style={{overflow: 'scroll', width: 0, height: 0}}>
                    <MauRanking2 size={1280} dataTable={state.data} dataSeason={state.dataSeason} id={idMau2.current} />
                  </div>
                  <MauRanking2 size={468} dataTable={state.data} dataSeason={state.dataSeason} />
                </>
              )}
            </Box>
          </Box>
        </Flex>
      </Box>
    )
  } else {
    return (
      <Box height={'100%'} display="flex" flexDirection={'column'} justifyContent="space-between">
        <Flex display={'flex'} flexDirection={'row'} justifyContent={'space-between'} w={'100%'} height={'46%'}>
          <Box _hover={{borderColor: '#11368f !important'}} backgroundColor="#eeeeee" style={css} className="pointer" width={'24%'} height={'auto'} onClick={select(1)}>
            <Image src={thumbnail['type1'][lang]} />
          </Box>
          <Box _hover={{borderColor: '#11368f !important'}} style={css} className="pointer" width={'24%'} height={'100%'} onClick={select(2)}>
            <Image src={thumbnail['type2'][lang]} />
          </Box>
          <Box backgroundColor={'#eeeeee'} className="pointer" p={4} width={'24%'} height={'auto'} position="relative">
            <MdUpdate size={30} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%'}} />
          </Box>
          <Box backgroundColor={'#eeeeee'} className="pointer" p={4} width={'24%'} height={'auto'} position="relative">
            <MdUpdate size={30} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%'}} />
          </Box>
        </Flex>
      </Box>
    )
  }
}

const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return <img {...props} src={data} alt="" />
}

function areEqual(p, n) {
  return true
}

export default React.memo(ExportRanking, areEqual)
