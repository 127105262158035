import React from 'react'
import moment from 'moment'
import img from '../../../public/static/image-background/logo-v-02.png'
import {json} from 'react-router-dom'
import config from '../../../constants/config'
import {useTranslation, formatDateTime, getBase64} from '../../../constants/helpers'

const Mau1 = ({isDisabledTime, dataMatch, dataMatchDetail, size, id}) => {
  const {t} = useTranslation()
  const width = size
  const px = size / 100
  const color = {
    first: '#f48220',
    second: '#325ea8',
    third: '#ffe5c2',
    fourth: '#1d4380'
  }
  const [matchDetail, setMatchDetail] = React.useState(dataMatchDetail)
  React.useEffect(() => {
    setMatchDetail(dataMatchDetail)
  }, [])
  let matchDetailHome = dataMatchDetail
    .filter(i => (i?.team?.id == dataMatch?.homeTeam?.id && i?.type != 'own_goal') || (i?.team?.id == dataMatch?.awayTeam?.id && i?.type === 'own_goal'))
    ?.sort(function (a, b) {
      return a.time - b.time
    })
  let matchDetailAway = dataMatchDetail
    .filter(i => (i?.team?.id == dataMatch?.awayTeam?.id && i?.type != 'own_goal') || (i?.team?.id == dataMatch?.homeTeam?.id && i?.type === 'own_goal'))
    ?.sort(function (a, b) {
      return a.time - b.time
    })

  const arrName = {quarterfinals: 'TỨ KẾT', semifinal: 'BÁN KẾT', '3rdPlace': 'TRANH HẠNG 3', final: 'CHUNG KẾT'}

  return (
    <div id={id} className="bg-match-mau1" style={{width: width, minHeight: width, padding: `${1.6 * px}px`, fontFamily: 'SFUHelveticaCondensedBold'}}>
      <div style={{width: `100%`, minHeight: `calc(${width}px - ${3.2 * px}px)`, padding: `${px * 1.2}px`, backgroundColor: '#fff', position: 'relative'}}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div style={{flex: 1}}>
            <Image src={dataMatch?.season?.logo} style={{width: `${px * 6}px`, height: 'max-content'}} />
          </div>
          <div style={{flex: 6}}>
            <h1 style={{textAlign: 'center', color: color.fourth, fontSize: `${2.5 * px}px`, textTransform: 'uppercase'}}>{dataMatch?.season?.name}</h1>
          </div>
          <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
            <Image src={img} style={{width: `${px * 6}px`, height: 'max-content'}} />
          </div>
        </div>
        <h1 style={{textAlign: 'center', color: color.first, fontSize: `${4.5 * px}px`, textTransform: 'uppercase', marginTop: `${px}px`}}>{t('match_result')}</h1>
        <div style={{width: '100%'}}>
          <h2 style={{textTransform: 'uppercase', margin: '0 auto', width: 'max-content', color: '#fff', backgroundColor: color.fourth, padding: `${0.5 * px}px ${5 * px}px`, borderRadius: `${1.5 * px}px`, marginTop: `${px}px`, fontSize: `${2.6 * px}px`}}>
            {t('round')} {t(dataMatch?.numberRound) || dataMatch?.numberRound}
          </h2>
        </div>
        <p style={{color: color.fourth, textAlign: 'center', marginTop: `${px}px`, fontSize: `${2.6 * px}px`}}>{formatDateTime(dataMatch?.dateTime, 'dateTime')}</p>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center'}}>
            <div style={{marginRight: `${px}px`}}>{dataMatch?.homeTeam?.isGiveUp && <p style={{color: '#fff', backgroundColor: 'red', fontSize: `${1.5 * px}px`, padding: `0 ${1.5 * px}px`, borderRadius: `${px}px`, minWidth: 'max-content'}}>Xử thua</p>}</div>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Image src={dataMatch?.homeTeam?.logo} style={{width: `${20 * px}px`, height: `${20 * px}px`, borderRadius: '50%', border: `${0.4 * px}px solid ${color.fourth}`, objectFit: 'contain'}} />
              <h2 style={{textAlign: 'center', textTransform: 'uppercase', fontSize: `${3 * px}px`, color: color.fourth}}>{dataMatch?.homeTeam?.name}</h2>
            </div>
          </div>
          <div style={{flex: 0.8}}>
            {!dataMatch?.homeTeam?.isGiveUp &&
              !dataMatch?.awayTeam?.isGiveUp && ( // ko doi nao bo cuoc
                <p style={{color: color.first, fontSize: `${12 * px}px`, textAlign: 'center'}}>
                  {dataMatch?.homeTeam?.score}
                  <span style={{margin: `0 ${px}px`}}>-</span>
                  {dataMatch?.awayTeam?.score}
                </p>
              )}
            {dataMatch?.homeTeam?.isGiveUp &&
              !dataMatch?.awayTeam?.isGiveUp && ( // doi nha bo cuoc
                <p style={{color: color.first, fontSize: `${12 * px}px`, textAlign: 'center'}}>
                  0<span style={{margin: `0 ${px}px`}}>-</span>
                  {dataMatch?.awayTeam?.giveUpScore}
                </p>
              )}
            {!dataMatch?.homeTeam?.isGiveUp &&
              dataMatch?.awayTeam?.isGiveUp && ( // doi khach bo cuoc
                <p style={{color: color.first, fontSize: `${12 * px}px`, textAlign: 'center'}}>
                  {dataMatch?.homeTeam?.giveUpScore}
                  <span style={{margin: `0 ${px}px`}}>-</span>0
                </p>
              )}
            {dataMatch?.homeTeam?.isGiveUp &&
              dataMatch?.awayTeam?.isGiveUp && ( // ca 2 doi cung bo cuoc
                <p style={{color: color.first, fontSize: `${12 * px}px`, textAlign: 'center'}}>
                  <span style={{margin: `0 ${px}px`}}>-</span>
                </p>
              )}
            {dataMatch?.hasPenalty && ( // co da penalty
              <div>
                <p style={{textTransform: 'uppercase', textAlign: 'center'}}>{`(${t('pk')}: ${dataMatch?.homeTeam?.penaltyScore} - ${dataMatch?.awayTeam?.penaltyScore})`}</p>
              </div>
            )}
          </div>
          <div style={{flex: 1, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Image src={dataMatch?.awayTeam?.logo} style={{width: `${20 * px}px`, height: `${20 * px}px`, borderRadius: '50%', border: `${0.4 * px}px solid ${color.fourth}`, objectFit: 'contain'}} />
              <h2 style={{textAlign: 'center', textTransform: 'uppercase', fontSize: `${3 * px}px`, color: color.fourth}}>{dataMatch?.awayTeam?.name}</h2>
            </div>
            <div style={{marginRight: `${px}px`}}>{dataMatch?.awayTeam?.isGiveUp && <p style={{color: '#fff', backgroundColor: 'red', fontSize: `${1.5 * px}px`, padding: `0 ${1.5 * px}px`, borderRadius: `${px}px`, minWidth: 'max-content'}}>Xử thua</p>}</div>
          </div>
        </div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <div style={{flex: 1, paddingRight: `${5 * px}px`}}>
            {matchDetailHome?.map((i, idx) => (
              <Item key={`${idx}-${new Date()}`} dataItem={i} typeTeam="home" px={px} matchDetail={matchDetailHome} isDisabledTime={isDisabledTime} />
            ))}
          </div>
          <div style={{flex: 1, paddingLeft: `${5 * px}px`}}>
            {matchDetailAway?.map((i, idx) => (
              <Item key={`${idx}-${new Date()}`} dataItem={i} typeTeam="away" px={px} matchDetail={matchDetailAway} isDisabledTime={isDisabledTime} />
            ))}
          </div>
        </div>
        <p style={{textAlign: 'center', color: color.first, fontSize: `${2 * px}px`, position: 'absolute', bottom: `${2.8 * px}px`, width: '100%'}}>{window.sessionStorage.getItem('HomeUrl') || 'VSPORTS.VN'}</p>
      </div>
    </div>
  )
}
const Item = ({dataItem, typeTeam, px, matchDetail, isDisabledTime}) => {
  let img = ''
  let h = ''
  let w = ''
  switch (dataItem.type) {
    case 'goal':
      img = config.goal
      h = '15px'
      w = '15px'
      break
    case 'own_goal':
      img = config.own_goal
      h = '15px'
      w = '15px'
      break
    case 'yellow_card':
      img = config.yellow_card
      h = '15px'
      w = '11px'
      break
    case 'red_card':
      img = config.red_card
      h = '15px'
      w = '11px'
      break
    case 'change_player':
      img = config.change_player
      h = '15px'
      w = '15px'
      break

    default:
      img = config.yellow_red_card
      h = '15px'
      w = '15px'
  }
  const yellow_card_by_id = matchDetail
    ?.sort(function (a, b) {
      return a.time - b.time
    })
    ?.filter(i => i?.player?.id === dataItem?.player?.id && i?.type === 'yellow_card')

  if (typeTeam == 'home') {
    return (
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', marginTop: `${px}px`}}>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: `${px}px`, fontSize: `${2 * px}px`}}>
          {dataItem?.type === 'own_goal' && <p>{`(OG)`}</p>}
          <p style={{marginRight: `${px}px`}}>{dataItem?.player?.name}</p>
          <p>{dataItem?.player?.shirtNumber !== null && dataItem?.player?.shirtNumber !== undefined && !dataItem?.player?.shirtNumber?.trim?.() ? `(${dataItem?.player?.shirtNumber})` : ''}</p>
        </div>
        {!isDisabledTime && (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: `${px}px`, fontSize: `${2 * px}px`}}>
            <p>{Math.floor(dataItem?.time / 60)}'</p>
          </div>
        )}
        {yellow_card_by_id[yellow_card_by_id.length - 1]?.id === dataItem?.id && yellow_card_by_id.length > 1 ? (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: `${2 * px}px`, justifyContent: 'center'}}>
            <Image src={config.yellow_red_card} style={{width: `${2 * px}px`}} />
          </div>
        ) : (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: `${2 * px}px`, justifyContent: 'center'}}>
            <Image src={img} style={{width: `${2 * px}px`}} />
          </div>
        )}
      </div>
    )
  } else {
    return (
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', marginTop: `${px}px`}}>
        {yellow_card_by_id[yellow_card_by_id.length - 1]?.id === dataItem?.id && yellow_card_by_id.length > 1 ? (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: `${2 * px}px`, justifyContent: 'center'}}>
            <Image src={config.yellow_red_card} style={{width: `${2 * px}px`}} />
          </div>
        ) : (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: `${2 * px}px`, justifyContent: 'center'}}>
            <Image src={img} style={{width: `${2 * px}px`}} />
          </div>
        )}
        {!isDisabledTime && (
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: `${px}px`, fontSize: `${2 * px}px`}}>
            <p>{Math.floor(dataItem?.time / 60)}'</p>
          </div>
        )}
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: `${px}px`, fontSize: `${2 * px}px`}}>
          <p>{dataItem?.player?.shirtNumber !== null && dataItem?.player?.shirtNumber !== undefined && !dataItem?.player?.shirtNumber?.trim?.() ? `(${dataItem?.player?.shirtNumber})` : ''}</p>
          <p style={{marginLeft: `${px}px`}}>{dataItem?.player?.name}</p>
          {dataItem?.type === 'own_goal' && <p>{`(OG)`}</p>}
        </div>
      </div>
    )
  }
}
const Image = props => {
  const [data, setData] = React.useState(null)
  React.useEffect(() => {
    getBase64(props.src).then(r => {
      setData(r)
    })
  }, [props.src])
  return <img {...props} src={data} alt="" />
}

function areEqual(p, n) {
  return JSON.stringify(p.isDisabledTime) === JSON.stringify(n.isDisabledTime) && JSON.stringify(p.dataMatch) === JSON.stringify(n.dataMatch) && JSON.stringify(p.dataMatchDetail) === JSON.stringify(n.dataMatchDetail)
}

export default React.memo(Mau1, areEqual)
