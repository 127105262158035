import config from './config'
import moment from 'moment'
import Vi from '../public/static/locales/vi.json'
import En from '../public/static/locales/en.json'
import {useSearchParams} from 'react-router-dom'

export function getBase64(url) {
  return new Promise(resolve => {
    const tmp = url.replace('vsports.blob.core.windows.net', 'vsports-assets-hfevhbaxghane9f2.z01.azurefd.net')
    if (!tmp.includes('http')) {
      fetch(tmp, {cache: 'reload'})
        .then(response => response.blob())
        .then(blob => {
          if (blob.type.includes('json')) throw new Error()
          var reader = new FileReader()
          reader.onload = function () {
            resolve(this.result)
          }
          // console.log('blodddd1', blob)
          reader.readAsDataURL(blob)
        })
        .catch(e => {
          fetch(config.logo, {cache: 'reload'})
            .then(response => response.blob())
            .then(blob => {
              var reader = new FileReader()
              reader.onload = function () {
                resolve(this.result)
              }
              // console.log('blodddd2', blob)
              reader.readAsDataURL(blob)
            })
        })
      return
    }
    fetch('https://upload.vsports.ai/getBase64?url=' + encodeURIComponent(tmp), {cache: 'reload'})
      .then(response => response.json())
      .then(blob => {
        if (!blob.base64) throw new Error()
        resolve(blob.base64)
      })
      .catch(e => {
        fetch(tmp, {cache: 'reload'})
          .then(response => response.blob())
          .then(blob => {
            if (blob.type.includes('json')) throw new Error()
            var reader = new FileReader()
            reader.onload = function () {
              resolve(this.result)
            }
            // console.log('blodddd1', blob)
            reader.readAsDataURL(blob)
          })
          .catch(e => {
            fetch(config.logo, {cache: 'reload'})
              .then(response => response.blob())
              .then(blob => {
                var reader = new FileReader()
                reader.onload = function () {
                  resolve(this.result)
                }
                // console.log('blodddd2', blob)
                reader.readAsDataURL(blob)
              })
          })
      })
  })
}

export const formatDateTime = (value, format) => {
  const lang = window.sessionStorage.getItem('lang')
  let valueFormated = 'MM-DD-YYYY'
  if (format == 'date') {
    switch (lang) {
      case 'vi':
        valueFormated = 'DD-MM-YYYY'
        break
      case 'en':
        valueFormated = 'MM-DD-YYYY'
        break
      default:
        valueFormated = 'MM-DD-YYYY'
    }
  }
  if (format == 'dateTime') {
    switch (lang) {
      case 'vi':
        valueFormated = 'HH:mm DD-MM-YYYY'
        break
      case 'en':
        valueFormated = 'hh:mm A MM-DD-YYYY'
        break
      default:
        valueFormated = 'hh:mm A MM-DD-YYYY '
    }
  }
  if (format == 'time') {
    switch (lang) {
      case 'vi':
        valueFormated = 'HH:mm'
        break
      case 'en':
        valueFormated = 'hh:mm A'
        break
      default:
        valueFormated = 'hh:mm A'
    }
  }
  return moment(value).format(valueFormated)
}

export const titleTemplate = (value = []) => {
  let title = 'league_schedule' //league_results //     leagues&results//
  if (value.every(i => i.isCompleted)) {
    title = 'league_results'
  }
  if (value.every(i => !i.isCompleted)) {
    title = 'league_schedule'
  }
  if (value.some(i => i.isCompleted) && value.some(i => !i.isCompleted)) {
    title = 'leagues&results'
  }
  return title
}

const languages = {en: En, vi: Vi}

export const useTranslation = () => {
  const lang = window.sessionStorage.getItem('lang')
  function t(key, prefixes) {
    if (!languages[lang] || !languages[lang][key]) {
      console.warn(`No string '${key}' for locale '${lang}'`)
    }
    let translated = languages[lang][key] || key || ''
    if (typeof prefixes === 'object') {
      Object.keys(prefixes).forEach(k => {
        translated = translated.replace(`{{${k}}}`, prefixes[k])
      })
    }

    return translated
  }

  return {t, locale: lang}
}

export function sortMatchesAndRounds(rounds) {
  return [...rounds]
    .sort((a, b) => a?.dataGo?.length - b?.dataGo?.length)
    .filter(round => round?.numberRound?.numberRound != '3rdPlace')
    .reduce((prev, round, index) => {
      const nextRound = prev[index - 1]
      // console.log('-----------------------------------')
      // console.log('numberRound', round.numberRound.numberRound)
      // console.log('nextRound', nextRound)

      if (!nextRound) return [...prev, {...round}]

      const matches = [...(round.dataGo || [])]
      const prevMatches = [...(nextRound.dataGo || [])]
      const sortedMatches = []
      for (const prevMatch of prevMatches) {
        const homeMatch = [...matches].find(match => [match?.homeTeam?.id, match?.awayTeam?.id].includes(prevMatch?.homeTeam?.id))
        const awayMatch = [...matches].find(match => [match?.homeTeam?.id, match?.awayTeam?.id].includes(prevMatch?.awayTeam?.id))

        if (homeMatch) sortedMatches.push({...homeMatch})
        if (awayMatch) sortedMatches.push({...awayMatch})
      }

      const excludedMatches = matches.filter(match => !sortedMatches.find(sortedMatch => sortedMatch.id == match.id))
      // console.log('excludedMatches', excludedMatches)
      sortedMatches.push(...excludedMatches)

      // console.log(
      //   'sortedMatches',
      //   [...sortedMatches].map(({homeTeam, awayTeam, numberGame, nextGame}) => ({numberGame, nextGame, homeTeamName: homeTeam.name, awayTeamName: awayTeam.name}))
      // )

      return [...prev, {...round, dataGo: [...sortedMatches]}]
    }, [])
    .reverse()
}
