import React from 'react'
import * as htmlToImage from 'html-to-image'
import JsZip from 'jszip'
import FileSaver from 'file-saver'
import {useParams} from 'react-router-dom'
import Mau1 from './layoutthedeo/mau1.js'
import Mau2 from './layoutthedeo/mau2.js'
import * as leaguesAPI from '../../api/leagues'
import {Button, Checkbox, Flex, Text} from '@chakra-ui/react'
import config from '../../constants/config.js'

const TheDeoAll = () => {
  let {leagueId, seasonId} = useParams()
  const [exportIndex, setExportIndex] = React.useState(-1)
  const [exportIndexATeam, setExportIndexATeam] = React.useState(-1)
  const [teams, setTeams] = React.useState([])
  const [state, setState] = React.useState({
    loading: true,
    data: [],
    page: 1,
    perPage: 600
  })
  const templates = [
    {key: 'mau1', thumbnail: '/thedeomau1.jpg'},
    {key: 'mau2', thumbnail: 'https://19429431-vsports.s3-hcm5-r1.longvan.net/thedeo-thumbnail.png'}
  ]
  const [selectedShowBirthDay, setSelectedShowBirthDay] = React.useState(true)
  const [mauId, setMauId] = React.useState('mau1')
  const [seasonData, setSeasonData] = React.useState(null)
  const [teamData, setTeamData] = React.useState({})
  const [blobs, setBlobs] = React.useState([])
  const [blobsATeam, setBlobsATeams] = React.useState([])

  React.useEffect(() => {
    fetchData()
    fetchDataMemberTeamsToExport()
  }, [])
  const fetchData = () => {
    try {
      leaguesAPI.getTeamAcceptedBySeasonId(leagueId, seasonId, state.page, state.perPage).then(r => {
        setState(s => ({...s, data: r.data}))
        setTeams(r?.data || [])
        setTeamData(r)
      })
    } catch (error) {
      console.log(error)
    }
  }
  const fetchDataMemberTeamsToExport = async teamId => {
    try {
      const season = await leaguesAPI.getSeasonById(leagueId, seasonId).then(r => r)
      setSeasonData(season)
    } catch (error) {}
  }

  React.useEffect(() => {
    // if (exportIndex > -1 && exportIndexATeam > -1 && teams.length > 0) {
    if (exportIndexATeam === teams?.length) {
      exportZip()
    } else {
      if (exportIndex === teams[exportIndexATeam]?.members?.length) {
        saveFolder()
      }
    }
    // }
  }, [exportIndexATeam, exportIndex])

  const xuat = mauId => async () => {
    if (!seasonData) return alert('Không tìm thấy mùa giải')
    setMauId(mauId)
    setExportIndexATeam(0)
    setExportIndex(0)
    setBlobs([])
  }

  const saveFolder = () => {
    setBlobsATeams(s => [...s, blobs])
    setBlobs([])
    setExportIndexATeam(s => s + 1)
    setExportIndex(0)
  }
  const exportBlob = () => {
    htmlToImage
      .toBlob(document.getElementById('thedeo'), {pixelRatio: 1, quality: 1, cacheBust: true})
      .then(blob => {
        setBlobs(s => [...s, blob])
        setExportIndex(i => i + 1)
      })
      .catch(error => {
        console.error('oops, something went wrong!', error)
      })
  }

  const exportZip = () => {
    try {
      const zip = JsZip()
      blobsATeam.forEach((blobsTemp, idex) => {
        blobsTemp.forEach((blob, idx) => {
          zip.folder(`${teams[idex]?.name}`).file(`TheThiDau-${teams[idex]?.members[idx]?.name}.png`, blob)
        })
      })
      zip.generateAsync({type: 'blob'}).then(zipFile => {
        const fileName = `The-Deo-Tat-Ca-Doi.zip`
        setExportIndex(-1)
        setExportIndexATeam(-1)
        setBlobs([])
        setBlobsATeams([])
        return FileSaver.saveAs(zipFile, fileName)
      })
    } catch (error) {
      console.log('error', error)
    }
  }
  const handleChangeSelect = ({target}) => {
    setSelectedShowBirthDay(s => !s)
  }

  return (
    <div>
      <div
        style={{
          opacity: 0,
          zIndex: '10',
          display: 'inline-flex',
          overflow: 'hidden',
          position: 'absolute',
          top: -400,
          left: 0
        }}>
        {mauId === 'mau1' && exportIndexATeam > -1 && exportIndexATeam > -1 && teams[exportIndexATeam]?.members[exportIndex] && exportIndexATeam <= teams.length - 1 && <Mau1 id="thedeo" onLoadedParent={exportBlob} player={teams[exportIndexATeam]?.members[exportIndex]} isShowBirthDay={selectedShowBirthDay} season={seasonData} team={teams[exportIndexATeam]} />}
        {mauId === 'mau2' && exportIndexATeam > -1 && exportIndexATeam > -1 && teams[exportIndexATeam]?.members[exportIndex] && exportIndexATeam <= teams.length - 1 && <Mau2 id="thedeo" onLoadedParent={exportBlob} isShowBirthDay={selectedShowBirthDay} player={teams[exportIndexATeam]?.members[exportIndex]} season={seasonData} team={teams[exportIndexATeam]} />}
      </div>
      <div style={{display: 'flex', flexDirection: 'column', flexWrap: 'wrap', paddingTop: 0}}>
        <Flex justifyContent="center" alignItems="center">
          <Flex ml="" alignItems="center">
            <Checkbox isChecked={selectedShowBirthDay} onChange={handleChangeSelect} />
            <Text ml="2" textAlign="center" fontFamily="SFUHelveticaCondensedBold" color="#1d4380">
              Hiện ngày sinh
            </Text>
          </Flex>
        </Flex>
        <Flex>
          {templates.map((i, idx) => (
            <div key={idx} style={{margin: '8px', position: 'relative', cursor: 'pointer'}} onClick={() => setMauId(i.key)}>
              <img className={i?.key.includes(mauId) ? 'rounded-border-gradient' : ''} src={i.thumbnail ?? config.logo} style={{width: '200px', height: '300px', padding: '10px'}} />
              {exportIndex > -1 && i.key === mauId && (
                <div style={{position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, backgroundColor: 'rgba(0,0,0,0.7)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <span style={{color: '#fff', textAlign: 'center'}}>Đang tải {Math.round((exportIndexATeam * 100) / teams?.length)}%</span>
                </div>
              )}
              {i.key === mauId && (
                <Flex marginTop="1rem" justifyContent="center">
                  {exportIndex === -1 && (
                    <Button ml={4} colorScheme="blue" onClick={xuat(mauId)}>
                      Tải xuống
                    </Button>
                  )}
                  {/* {exportIndex > -1 && i.key === mauId && (
                    <Button ml={4} colorScheme="blue" isDisabled={true}>
                      Đang tải {Math.round((exportIndex * 100) / players.length)}%
                    </Button>
                  )} */}
                </Flex>
              )}
            </div>
          ))}
        </Flex>
      </div>
    </div>
  )
}

function areEqual(p, n) {
  return true
}

export default React.memo(TheDeoAll, areEqual)
