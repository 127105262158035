export default {
  logo: 'https://19429431-vsports.s3-hcm5-r1.longvan.net/vstation.jpg',
  background: 'https://19429431-vsports.s3-hcm5-r1.longvan.net/cover.jpg',
  eventId: 'SuEG6sF1',
  goal: 'https://19429431-vsports.s3-hcm5-r1.longvan.net/1659517848472.png',
  own_goal: 'https://19429431-vsports.s3-hcm5-r1.longvan.net/1659517848322.png',
  yellow_card: 'https://19429431-vsports.s3-hcm5-r1.longvan.net/1659517847822.png',
  red_card: 'https://19429431-vsports.s3-hcm5-r1.longvan.net/1659517847658.png',
  change_player: 'https://19429431-vsports.s3-hcm5-r1.longvan.net/1659517848186.png',
  yellow_red_card: 'https://19429431-vsports.s3-hcm5-r1.longvan.net/hRkGaUoDjO.png'
}
